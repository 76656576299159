.notification {
  font-family: 'Gill Sans', sans-serif;
  letter-spacing: 1.2px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.tabs-custom > div:last-child > div > button {
  border: 2px #b62a2a solid !important;
  text-transform: initial;
  font-weight: 600;
}

.tabs-custom > div:last-child > div > button:first-child::before {
  font-family: fontAwesome;
  content: "\f053\00a0";
}


.tabs-custom > div:last-child  > span {
  background-color: #b62a2a;
}

.date-picker-custom {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 8px !important;
}

.date-picker-custom > div {
  max-width: 160px;
}

.date-picker-custom > div > input {
  padding-top: 8px;
  padding-bottom: 8px;
}

.date-picker-custom > div > fieldset {
  border: 2px #b62a2a solid !important;
}

.date-picker-custom > div > div > button {
  padding-top: 5px;
  padding-bottom: 5px;
}

.date-picker-custom > div > div > button > span > svg {
  color: #b62a2a;
}